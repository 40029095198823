import { registerDestructor } from '@ember/destroyable';
import { tracked } from '@glimmer/tracking';
import constrainValue from 'editor/utils/constrain-value';
import Crc32 from 'editor/utils/crc32';
import elementExists from 'editor/utils/element-exists';
import { beatTicks } from 'editor/utils/music-utils';
import Reader from 'editor/utils/reader';
import unique from 'editor/utils/unique';
import Writer from 'editor/utils/writer';
import { createNanoEvents, type Emitter } from 'nanoevents';

const SONG_HEADER_LENGTH = 32;
const SONG_OFFSETS_LENGTH = 80;
const SONG_MAX_PARTS = 32;

export enum SectionType {
  MAIN,
  FILL,
  TRANSITION,
}

export enum PartType {
  INTRO,
  PART,
  OUTRO,
}

class SongMeta {
  uuid?: string;
  drumsetName: string = '';
  @tracked defaultDrumsetName: string = '';

  constructor() {
    this.uuid = crypto.randomUUID();
  }
}

class SongInfo {
  @tracked drumset: string = '';
  @tracked bpm: number = 120;
  loop: number = 0;
  @tracked parts: SongPartInfo[] = [];
  intro: SongPartInfo = new SongPartInfo();
  outro: SongPartInfo = new SongPartInfo();

  get isDirty() {
    return (
      this.intro.isDirty ||
      this.outro.isDirty ||
      this.parts.some((part) => part.isDirty)
    );
  }

  markClean() {
    this.intro.markClean();
    this.outro.markClean();
    this.parts.forEach((part) => part.markClean());
  }
}

function reorderFills(fills: number[]) {
  const positives = fills.filter((num) => num >= 0);
  const negatives = fills.filter((num) => num < 0);
  // Return the first 8 fills
  return [...positives, ...negatives].slice(0, 8);
}

interface SongPartEvents {
  addTrack: (
    sectionType: SectionType,
    sectionIndex: number,
    trackIndex: number,
  ) => void;
  removeTrack: (sectionType: SectionType, sectionIndex: number) => void;
}

export class SongPart {
  type: PartType;
  info: SongPartInfo;
  midi: SongTrack[];
  emmitter: Emitter<SongPartEvents>;
  _isDirty = false;
  @tracked numFills: number;

  constructor(partName: PartType, info: SongPartInfo, midi: SongTrack[]) {
    this.type = partName;
    this.info = info;
    this.midi = midi;
    this.emmitter = createNanoEvents();
    this.numFills = this.info.fillCount; // this is just a hack for PR #112, so that undoing a fill sort updates visually
  }

  on<E extends keyof SongPartEvents>(event: E, callback: SongPartEvents[E]) {
    return this.emmitter.on(event, callback);
  }

  get isDirty() {
    return this.info.isDirty || this.tracks.some((track) => track.isDirty);
  }

  markDirty() {
    this.info.markDirty();
  }

  markClean() {
    this.info.markClean();
    this.tracks.forEach((track) => track.markClean());
  }

  get timeSignatureNumerator() {
    return (
      this.main?.timeSignatureNumerator ?? this.info.timeSignatureNumerator
    );
  }

  get timeSignatureDenominator() {
    return (
      this.main?.timeSignatureDenominator ?? this.info.timeSignatureDenominator
    );
  }

  getTrack(sectionType: SectionType, index?: number): SongTrack | undefined {
    switch (sectionType) {
      case SectionType.FILL:
        return this.fills[index ?? 0];
      case SectionType.TRANSITION:
        return this.transition;
      default:
        return this.main;
    }
  }

  addTrack(sectionType: SectionType, sectionIndex: number, track: SongTrack) {
    let trackIndex = this.midi.indexOf(track);
    if (trackIndex === -1) {
      this.midi.push(track);
      trackIndex = this.midi.length - 1;
    }
    this._setTrackIndex(sectionType, sectionIndex, trackIndex);
    this.emmitter.emit('addTrack', sectionType, sectionIndex, trackIndex);
    return sectionIndex;
  }

  removeTrack(sectionType: SectionType, sectionIndex: number) {
    this._removeTrackIndex(sectionType, sectionIndex, -1);
    this.emmitter.emit('removeTrack', sectionType, sectionIndex);
  }

  renameTrack(sectionType: SectionType, sectionIndex: number, name: string) {
    const track = this.getTrack(sectionType, sectionIndex);
    if (!track) {
      throw new Error('Track not found');
    }
    track.name = name;
  }

  _setTrackIndex(
    sectionType: SectionType,
    sectionIndex: number,
    trackIndex: number,
  ) {
    switch (sectionType) {
      case SectionType.FILL: {
        const fillIndexes = this.info.fillFileIndexes.toSpliced(
          sectionIndex,
          0,
          trackIndex,
        );
        this.info.fillFileIndexes = reorderFills(fillIndexes);
        break;
      }
      case SectionType.TRANSITION:
        this.info.transitionFileIndex = trackIndex;
        break;
      default:
        this.info.mainLoopFileIndex = trackIndex;
        break;
    }
  }

  _removeTrackIndex(
    sectionType: SectionType,
    sectionIndex: number,
    trackIndex: number,
  ) {
    switch (sectionType) {
      case SectionType.FILL: {
        const fillIndexes = this.info.fillFileIndexes.toSpliced(
          sectionIndex,
          1,
          trackIndex,
        );
        this.info.fillFileIndexes = reorderFills(fillIndexes);
        break;
      }
      case SectionType.TRANSITION:
        this.info.transitionFileIndex = trackIndex;
        break;
      default:
        this.info.mainLoopFileIndex = trackIndex;
        break;
    }
  }

  get main() {
    return this.midi[this.info.mainLoopFileIndex];
  }

  set main(midi: SongTrack | undefined) {
    if (midi) {
      this.info.mainLoopFileIndex = this.midi.indexOf(midi);
    } else {
      this.info.mainLoopFileIndex = -1;
    }
  }

  get transition() {
    return this.midi[this.info.transitionFileIndex];
  }

  set transition(midi: SongTrack | undefined) {
    if (midi) {
      this.info.transitionFileIndex = this.midi.indexOf(midi);
    } else {
      this.info.transitionFileIndex = -1;
    }
  }

  get fills(): SongTrack[] {
    const fills = this.info.fillFileIndexes
    .map((index) => this.midi[index])
    .filter(elementExists)

    // this is just a hack for PR #112, so that undoing a fill sort updates visually
    // without the "if (this.numFills != fills.length) {", it would cause an infinite loop of rerenders.
    if (this.numFills != fills.length) {
      this.numFills = fills.length;
    }
    return fills;
  }

  set fills(midi: SongTrack[]) {
    this.info.fillFileIndexes = midi.map((m) => this.midi.indexOf(m));
  }

  get hasAccent() {
    return this.info.accentFileName !== '';
  }

  get accentFileName() {
    return this.info.accentFileName;
  }

  get accentVolume() {
    return this.info.effectVolume;
  }

  get shuffleFills() {
    return this.info.random;
  }

  set shuffleFills(shuffle: boolean) {
    this.info.random = shuffle;
  }

  get tracks() {
    return [this.main, ...this.fills, this.transition].filter(elementExists);
  }

  get trackIndexes() {
    return [
      this.info.mainLoopFileIndex,
      ...this.info.fillFileIndexes,
      this.info.transitionFileIndex,
    ].filter((index) => index >= 0);
  }

  copy(newTracks: SongTrack[]) {
    const newInfo = this.info.copy();
    if (this.midi !== newTracks) {
      // We are copying this part to a new song so we have to copy the tracks and update the part indexes
      const indexes = new Map<SongTrack, number>();
      let track, index;
      track = this.midi[this.info.mainLoopFileIndex]!;
      if (track) {
        if (indexes.get(track)) {
          newInfo.mainLoopFileIndex = indexes.get(track)!;
        } else {
          index = newTracks.length;
          newTracks.push(track);
          newInfo.mainLoopFileIndex = index;
          indexes.set(track, index);
        }
      }

      track = this.midi[this.info.transitionFileIndex]!;
      if (track) {
        if (indexes.get(track)) {
          newInfo.transitionFileIndex = indexes.get(track)!;
        } else {
          index = newTracks.length;
          newTracks.push(track);
          newInfo.transitionFileIndex = index;
          indexes.set(track, index);
        }
      }

      const fills = this.info.fillFileIndexes.filter((index) => index >= 0);
      const fillIndexes = fills.map((index) => {
        track = this.midi[index]!;
        if (indexes.get(track)) {
          return indexes.get(track)!;
        } else {
          index = newTracks.length;
          newTracks.push(track);
          indexes.set(track, index);
          return index;
        }
      });
      fillIndexes.push(...new Array(8 - fillIndexes.length).fill(-1));
      newInfo.fillFileIndexes = fillIndexes;

      // Now make a copy of each track so they are disconnected from the source song
      indexes.forEach((index, track) => {
        newTracks[index] = track.copy();
      });
    }
    return newInfo;
  }
}

export class SongPartInfo {
  _fillCount = 0;
  bpmDelta = 0;
  repeat = true;
  @tracked random = false;
  timeSignatureNumerator = 4;
  timeSignatureDenominator = 4;
  ticksToQuarter = 480;
  ticksPerBar = this.ticksToQuarter * 4;
  @tracked mainLoopFileIndex = -1;
  @tracked transitionFileIndex = -1;
  @tracked fillFileIndexes: number[] = new Array(8).fill(-1);
  @tracked accentFileName: string = '';
  // 0 - 200
  @tracked effectVolume: number = 100;
  loopCount: number = 0;
  _isDirty = false;

  get isDirty() {
    return this._isDirty;
  }

  markDirty() {
    this._isDirty = true;
  }

  markClean() {
    this._isDirty = false;
  }

  get fillCount() {
    return this.fillFileIndexes.filter((index) => index >= 0).length;
  }

  copy() {
    const part = new SongPartInfo();
    part._fillCount = this._fillCount;
    part.bpmDelta = this.bpmDelta;
    part.repeat = this.repeat;
    part.random = this.random;
    part.timeSignatureNumerator = this.timeSignatureNumerator;
    part.timeSignatureDenominator = this.timeSignatureDenominator;
    part.ticksToQuarter = this.ticksToQuarter;
    part.ticksPerBar = this.ticksPerBar;
    part.mainLoopFileIndex = this.mainLoopFileIndex;
    part.transitionFileIndex = this.transitionFileIndex;
    part.fillFileIndexes = [...this.fillFileIndexes];
    part.accentFileName = this.accentFileName;
    part.effectVolume = this.effectVolume;
    part.loopCount = this.loopCount;
    return part;
  }
}

export class MidiEvent {
  @tracked tick: number = 0;
  type: number = 0;
  @tracked note: number = 0;
  @tracked _velocity: number = 0;
  @tracked length: number = 0;
  isDirty = false;

  constructor(
    tick: number = 0,
    type: number = 0,
    note: number = 0,
    velocity: number = 0,
    length: number = 1920,
  ) {
    this.tick = tick;
    this.type = type;
    this.note = note;
    this.velocity = velocity;
    this.length = length;
  }

  markDirty() {
    this.isDirty = true;
  }

  markClean() {
    this.isDirty = false;
  }

  set velocity(velocity: number) {
    this._velocity = velocity < 0 ? 0 : velocity > 127 ? 127 : velocity;
  }

  get velocity() {
    return this._velocity;
  }

  copy() {
    return new MidiEvent(
      this.tick,
      this.type,
      this.note,
      this.velocity,
      this.length,
    );
  }

  asMap() {
    return {
      tick: this.tick,
      type: this.type,
      note: this.note,
      velocity: this.velocity,
      length: this.length,
    };
  }
}

export class SongTrack {
  dataOffset: number = 0;
  metaOffset: number = 0;
  crc: number = 0;
  @tracked fileName: string = '';
  info: SongTrackInfo = new SongTrackInfo();
  _isDirty = false;

  constructor(
    name: string = 'New Track',
    timeSignatureNumerator = 4,
    timeSignatureDenominator = 4,
  ) {
    this.name = name;
    this.info.timeSignatureNumerator = timeSignatureNumerator;
    this.info.timeSignatureDenominator = timeSignatureDenominator;
    this.info.tickCount =
      this.info.tickToQuarter *
      (4 / timeSignatureDenominator) *
      timeSignatureNumerator;
    this.info.barLength = this.info.tickCount;
  }

  get isDirty() {
    return this._isDirty || this.events.some((event) => event.isDirty);
  }

  markDirty() {
    this._isDirty = true;
  }

  markClean() {
    this._isDirty = false;
    this.events.forEach((event) => event.markClean());
  }

  get name() {
    return this.fileName
      .split('/')
      .pop()!
      .replace(/\.mid$/i, '');
  }

  set name(name: string) {
    this.fileName = `C:/Users/BBFF/${name}.mid`;
  }

  get tempo() {
    return this.bpm;
  }

  get bpm() {
    return this.info.bpm;
  }

  set bpm(bpm: number) {
    this.info.bpm = bpm;
  }

  get barCount() {
    return Math.ceil(this.info.tickCount / this.info.barLength);
  }

  set barCount(count: number) {
    this.info.tickCount = count * this.info.barLength - this.trigPos;
  }

  get barLength() {
    return this.info.barLength;
  }

  get trigPos() {
    // trigPos is unreliable
    // return this.info.trigPos;
    const diff = this.tickCount % this.barLength;
    if (diff === 0) {
      return 0;
    } else {
      return Math.abs(this.barLength - diff);
    }
  }

  get tickCount() {
    return this.info.tickCount;
  }

  // How many ticks appear after the end of the last bar (for fills)
  get extraTicks() {
    return (
      this.events.reduce((acc, event) => {
        return Math.max(acc, event.tick + event.length);
      }, this.tickCount) +
      this.trigPos -
      this.tickCount
    );
  }

  get totalTicks() {
    return this.tickCount + this.trigPos;
  }

  get tickToQuarter() {
    return this.info.tickToQuarter;
  }

  get timeSignatureNumerator() {
    return this.info.timeSignatureNumerator;
  }

  get timeSignatureDenominator() {
    return this.info.timeSignatureDenominator;
  }

  set timeSignatureNumerator(numerator: number) {
    this.info.timeSignatureNumerator = numerator;
  }

  set timeSignatureDenominator(denominator: number) {
    const adjustment = this.info.timeSignatureDenominator / denominator;
    this.info.timeSignatureDenominator = denominator;
    this.info.events = this.info.events.map((event) => {
      event.tick = event.tick * adjustment;
      event.length = event.length * adjustment;
      return event;
    });
    this.info.tickCount = this.info.tickCount * adjustment;
    this.info.barLength = this.info.barLength * adjustment;
  }

  get events() {
    return this.info.events;
  }

  filterEvents() {
    // Filter out overlapped events
    return this.events.filter((ev, index) => {
      // if the event tick is less than the length of the previous note, remove it
      for (let i = index - 1; i >= 0; i--) {
        const prev = this.events[i]!;
        // if the ev tick + length overlaps prev tick + length
        if (
          ev.note == prev.note &&
          ev.tick + ev.length > prev.tick &&
          ev.tick < prev.tick + prev.length
        ) {
          return true;
        }
      }
      return false;
    });
  }

  get instruments() {
    return this.events
      .filter((event) => event.type === 0)
      .map((event) => event.note)
      .filter((note, i, notes) => notes.indexOf(note) === i)
      .sort((a, b) => a - b)
      .reverse();
  }

  get noteOnEvents() {
    return this.events.filter((event) => event.type === 0);
  }

  eventsForInstrument(instrumentId: number) {
    return this.events
      .filter((event) => event.type === 0)
      .filter((event) => event.note === instrumentId);
  }

  addEvent(event: MidiEvent) {
    this.info.addEvent(event);
  }

  addEvents(events: MidiEvent[]) {
    this.info.addEvents(events);
  }

  removeEvent(event: MidiEvent) {
    this.info.removeEvent(event);
  }

  removeEvents(events: MidiEvent[]) {
    this.info.removeEvents(events);
  }

  copy() {
    const track = new SongTrack(this.name);
    track.info = this.info.copy();
    track.fileName = this.fileName;
    return track;
  }

  toMidi() {
    const writer = new Writer(1024, 1024);
    writer.setString('MThd', false);
    writer.setUint(6, false);
    writer.setShort(0, false);
    writer.setShort(1, false);
    writer.setShort(this.tickToQuarter, false);

    const trackData = trackToMidi(this);
    writer.setString('MTrk', false);
    writer.setUint(trackData.byteLength, false);
    writer.append(trackData);
    return writer.close();
  }
}

function trackToMidi(track: SongTrack) {
  const writer = new Writer(1024, 1024);

  // Write copyright meta event
  writer.setVarInt(0); // delta
  writer.setByte(0xff); // meta message
  writer.setByte(0x02); // Copyright
  // Copyright message written into the MIDI file
  // TODO: Replace with Singular Sound URL (or change message as needed)
  const copyright = 'BeatBuddy Manager: http://localhost';
  writer.setVarInt(copyright.length);
  writer.setString(copyright, false);

  // Write sequence name meta event
  writer.setVarInt(0); // delta
  writer.setByte(0xff); // meta message
  writer.setByte(0x03); // Sequence name
  writer.setVarInt(track.name.length);
  writer.setString(track.name, false);

  // Write tempo meta event
  writer.setVarInt(0); // delta
  writer.setByte(0xff); // meta message
  writer.setByte(0x51); // Tempo
  writer.setByte(3); // length
  const tempo = Math.ceil(60_000_000 / track.info.bpm);
  // Write tempo out in three bytes
  writer.setByte((tempo >> 16) & 0xff);
  writer.setByte((tempo >> 8) & 0xff);
  writer.setByte(tempo & 0xff);

  // Write time signature meta event
  writer.setVarInt(0); // delta
  writer.setByte(0xff); // meta message
  writer.setByte(0x58); // Tempo
  writer.setVarInt(4); // length
  writer.setByte(track.info.timeSignatureNumerator);
  writer.setByte(Math.log2(track.info.timeSignatureDenominator));
  writer.setByte(track.info.midiClocksPerMetronomeClick);
  writer.setByte(track.info.n32ndNotesPerMIDIQuarterNote);

  let badEvent = false;
  const events = track.events.flatMap((event) => {
    // Skip if the event's tick is too high
    if (event.tick > track.tickCount + track.info.tickToQuarter * 2) {
      // track.info.tickToQuarter * 2 is to account for extra ticks
      // unfortunately, the extra ticks are not accurate in the case of Gorans corrupted (?) files - extra ticks get messed up here.
      badEvent = true;
      return []; // Return an empty array to skip this event
    }
    return [
      {
        note: event.note,
        velocity: event.velocity,
        tick: event.tick,
      },
      {
        note: event.note,
        velocity: 0,
        tick: event.tick + event.length,
      },
    ];
  });
  events.sort((a, b) => {
    return a.tick - b.tick;
  });
  let ticks = 0;
  let lastStatus: number;
  events.forEach((event) => {
    const delta = event.tick - ticks;
    writer.setVarInt(delta);
    if (!lastStatus) {
      writer.setByte(0x90);
      lastStatus = 0x90;
    }
    writer.setByte(event.note);
    writer.setByte(event.velocity);
    ticks = event.tick;
  });

  // Ensure that the delta is always positive
  let delta = track.tickCount - ticks;
  if (delta < 0) {
    delta = 0;
  }
  writer.setVarInt(delta);
  writer.setByte(0xff);
  writer.setByte(0x2f);
  writer.setByte(0x00);

  if (badEvent) {
    logMessageToLocalStorage('Bad event in track ' + track.name);
    console.warn('Bad event in track', track.name);
  }

  return writer.close();
}

function logMessageToLocalStorage(message: string) {
  const timestamp = new Date().toISOString();
  const logEntry = `${timestamp} - ${message}\n`;

  const existingLogs = localStorage.getItem('logs') || '';
  localStorage.setItem('logs', existingLogs + logEntry);
}

export class SongTrackInfo {
  format: number = 0;
  track: number = 0;
  @tracked tickCount: number = 0;
  pickupNotesLength: number = 0;
  additionalNotesLength: number = 0;
  @tracked timeSignatureNumerator: number = 0;
  @tracked timeSignatureDenominator: number = 0;
  n32ndNotesPerMIDIQuarterNote: number = 8;
  midiClocksPerMetronomeClick: number = 24;
  tickToQuarter: number = 480;
  @tracked barLength: number = 0;
  @tracked trigPos: number = 0;
  bpm: number = 120;
  index: number = 0;
  @tracked _events: MidiEvent[] = [];

  get events() {
    return this._events;
  }

  set events(events: MidiEvent[]) {
    this._events = events.sort(sortMidiEvents);
  }

  addEvent(event: MidiEvent) {
    this.events = [...this.events, event];
  }

  addEvents(events: MidiEvent[]) {
    this.events = [...this.events, ...events];
  }

  removeEvent(event: MidiEvent) {
    this.events = this.events.filter((e) => e !== event);
  }

  removeEvents(events: MidiEvent[]) {
    this.events = this.events.filter((e) => !events.includes(e));
  }

  copy() {
    const track = new SongTrackInfo();
    track.format = this.format;
    track.track = this.track;
    track.tickCount = this.tickCount;
    track.pickupNotesLength = this.pickupNotesLength;
    track.additionalNotesLength = this.additionalNotesLength;
    track.timeSignatureNumerator = this.timeSignatureNumerator;
    track.timeSignatureDenominator = this.timeSignatureDenominator;
    track.n32ndNotesPerMIDIQuarterNote = this.n32ndNotesPerMIDIQuarterNote;
    track.midiClocksPerMetronomeClick = this.midiClocksPerMetronomeClick;
    track.tickToQuarter = this.tickToQuarter;
    track.barLength = this.barLength;
    track.trigPos = this.trigPos;
    track.bpm = this.bpm;
    track.index = this.index;
    track.events = this.events.map((event) => event.copy());
    return track;
  }
}

class SongHeader {
  type: string = 'BBSF';
  backwardsCompatibleVersion: number = 2;
  backwardsCompatibleRevision: number = 1;
  backwardsCompatibleBuild: number = 5712;
  crc: number = 0;
  flags: number = 0;
  version: number = 0;
  revision: number = 0;
  build: number = 0;
}

class SongOffsets {
  selfOffset: number = 0;
  metaOffset: number = 0;
  songOffset: number = 0;
  midiDataOffset: number = 0;
  midiMetaOffset: number = 0;
  midiPreambleOffset: number = 0;
  autoPilotOffset: number = 0;
  selfLength: number = 0;
  metaLength: number = 0;
  songLength: number = 0;
  midiPreambleLength: number = 0;
  midiMetaLength: number = 0;
  midiDataLength: number = 0;
  autoPilotLength: number = 0;
}

interface Events {
  rename: (name: string) => void;
  changeDrumset: (drumset: string) => void;
  addTrack: (
    part: SongPart,
    sectionType: SectionType,
    sectionIndex: number,
    trackIndex: number,
  ) => void;
  removeTrack: (
    part: SongPart,
    sectionType: SectionType,
    sectionIndex: number,
  ) => void;
}

export default class Song {
  header: SongHeader = new SongHeader();
  offsets: SongOffsets = new SongOffsets();
  meta: SongMeta = new SongMeta();
  info: SongInfo = new SongInfo();
  tracks: SongTrack[] = [];
  @tracked _name: string;
  emmitter: Emitter<Events>;
  _isDirty = false;

  constructor(name: string) {
    this._name = name;
    this.emmitter = createNanoEvents();
  }

  on<E extends keyof Events>(event: E, callback: Events[E]) {
    return this.emmitter.on(event, callback);
  }

  get isDirty() {
    return (
      this._isDirty ||
      this.tracks.some((track) => track._isDirty) ||
      this.allParts.some((part) => part.isDirty)
    );
  }

  markDirty() {
    this._isDirty = true;
  }

  markClean() {
    this._isDirty = false;
    this.tracks.forEach((track) => track.markClean());
    this.allParts.forEach((part) => part.markClean());
  }

  get isValid(): true | string {
    let response = '';
    this.parts.forEach((part) => {
      // The tracks here are for the main parts, not the fills (outro, intro, etc)
      if (part.tracks.length === 0) {
        response = 'Invalid Song, missing parts';
      }
    });
    if (response === '') {
      return true;
    } else {
      return response;
    }
  }

  get uuid() {
    return this.meta.uuid;
  }

  get name() {
    return this._name;
  }

  set name(name: string) {
    this._name = name;
    this.emmitter.emit('rename', name);
  }

  get drumsetName() {
    return this.meta.drumsetName;
  }

  set drumsetName(drumsetName: string) {
    this.meta.drumsetName = drumsetName;
  }

  get defaultDrumsetName() {
    return this.meta.defaultDrumsetName;
  }

  set defaultDrumsetName(drumsetName: string) {
    this.meta.defaultDrumsetName = drumsetName;
  }

  get drumset() {
    return this.info.drumset;
  }

  set drumset(drumset: string) {
    this.info.drumset = drumset;
    this.emmitter.emit('changeDrumset', drumset);
  }

  get sections() {
    return this.info.parts.length;
  }

  get intro() {
    return this.setupPartEvents(
      new SongPart(PartType.INTRO, this.info.intro, this.tracks),
    );
  }

  get outro() {
    return this.setupPartEvents(
      new SongPart(PartType.OUTRO, this.info.outro, this.tracks),
    );
  }

  get parts() {
    return this.info.parts.map((info) =>
      this.setupPartEvents(new SongPart(PartType.PART, info, this.tracks)),
    );
  }

  get allParts() {
    return [this.intro, ...this.parts, this.outro];
  }

  get usedTracks() {
    return this.allParts.flatMap((part) => part.tracks);
  }

  get effects(): string[] {
    return this.allParts
      .map((part) => part.accentFileName)
      .filter((effectFile) => effectFile !== '');
  }

  addPart(afterPart?: SongPart, clonePart?: SongPart) {
    let part: SongPartInfo;
    if (clonePart) {
      part = clonePart.copy(this.tracks);
    } else {
      part = new SongPartInfo();
      part.timeSignatureNumerator =
        afterPart?.timeSignatureNumerator ??
        this.parts[0]?.timeSignatureNumerator ??
        this.intro?.timeSignatureNumerator ??
        4;
      part.timeSignatureDenominator =
        afterPart?.timeSignatureDenominator ??
        this.parts[0]?.timeSignatureDenominator ??
        this.intro?.timeSignatureDenominator ??
        4;
      part.ticksPerBar = beatTicks(
        part.ticksToQuarter,
        part.timeSignatureDenominator,
      );
    }
    let index = this.parts.findIndex((p) => p.info == afterPart?.info);
    if (index === -1) {
      index = this.parts.length - 1;
    }
    return this.insertPart(
      this.setupPartEvents(new SongPart(PartType.PART, part, this.tracks)),
      index + 1,
    );
  }

  insertPart(part: SongPart, index?: number) {
    const info = part.info;
    if (typeof index === 'number') {
      this.info.parts = [
        ...this.info.parts.slice(0, index),
        info,
        ...this.info.parts.slice(index),
      ];
    } else {
      this.info.parts = [info, ...this.info.parts];
    }

    return part;
  }

  removePart(songPart: SongPart) {
    this.info.parts = this.info.parts.filter((p) => p !== songPart.info);
  }

  get tempo() {
    return this.info.bpm;
  }

  get bpm() {
    return this.info.bpm;
  }

  set bpm(bpm: number) {
    this.info.bpm = constrainValue(bpm, 40, 300);
    this.tracks.forEach((track) => (track.bpm = this.info.bpm));
  }

  setupPartEvents(part: SongPart) {
    const addTrackDestructor = part.on(
      'addTrack',
      (sectionType, sectionIndex, trackIndex) => {
        // If this is the first track, then set the song BPM from the track
        if (this.usedTracks.length === 1) {
          const track = this.usedTracks[0]!;
          this.bpm = track.bpm;
        }
        this.emmitter.emit(
          'addTrack',
          part,
          sectionType,
          sectionIndex,
          trackIndex,
        );
      },
    );
    const removeTrackDestructor = part.on(
      'removeTrack',
      (sectionType, sectionIndex) => {
        this.emmitter.emit('removeTrack', part, sectionType, sectionIndex);
      },
    );
    registerDestructor(part, () => {
      addTrackDestructor();
      removeTrackDestructor();
    });
    return part;
  }

  save() {
    // META
    const metaWriter = new Writer(256, 256);
    metaWriter.setString(`{${this.meta.uuid}}`, false, 40);
    metaWriter.setString(this.meta.drumsetName, false, 128);
    const padding = new Array(88).fill(90); // Z
    metaWriter.setBytes(new Uint8Array(padding));
    const metaOffset = SONG_HEADER_LENGTH + SONG_OFFSETS_LENGTH;
    const meta = metaWriter.close();
    const metaLength = meta.byteLength;

    // SONG
    const songWriter = new Writer(1024, 1024);
    songWriter.setUint(this.info.loop);
    songWriter.setUint(this.info.bpm);
    songWriter.setUint(this.info.parts.length);
    songWriter.setUint(0); // reserved
    songWriter.setString(this.info.drumset, false, 16);

    // Get all used tracks and setup a translation map for the track indexes
    const trackIndexes = this.allParts
      .flatMap((part) => part.trackIndexes)
      .filter(unique)
      .sort((a, b) => a - b);
    const trackMap = new Map();
    const usedTracks = trackIndexes.map((index, newIndex) => {
      trackMap.set(index, newIndex);
      return this.tracks[index]!;
    });

    writeSongPart(songWriter, this.info.intro, trackMap);
    writeSongPart(songWriter, this.info.outro, trackMap);
    this.info.parts.forEach((part) => {
      writeSongPart(songWriter, part, trackMap);
    });
    // Write blank parts as required by the file format
    for (let i = this.info.parts.length, ii = SONG_MAX_PARTS; i < ii; i++) {
      writeSongPart(songWriter, new SongPartInfo(), trackMap);
    }
    const songOffset = SONG_HEADER_LENGTH + SONG_OFFSETS_LENGTH + metaLength;
    const song = songWriter.close();
    const songLength = song.byteLength;

    // MIDI
    const midiPreambleWriter = new Writer(1024, 1024);
    const midiMetaWriter = new Writer(2048, 1024);
    const midiDataWriter = new Writer(5120, 1024);

    usedTracks.forEach((item) => {
      midiPreambleWriter.setUint(midiDataWriter.offset);
      midiPreambleWriter.setUint(midiMetaWriter.offset);
      let length = item.fileName.length + 1;
      const rem = length % 4;
      if (rem > 0) {
        length += 4 - rem;
      }
      midiMetaWriter.setString(item.fileName, false, length);
      writeMidiData(midiDataWriter, item);
      const crc = new Crc32();
      crc.update(midiMetaWriter.buffer, midiMetaWriter.offset);
      crc.update(midiDataWriter.buffer, midiDataWriter.offset);
      const crcValue = crc.getCRC(true);
      midiPreambleWriter.setUint(crcValue);
      midiPreambleWriter.setUint(0); // reserved
      midiPreambleWriter.setUint(0); // reserved
      midiPreambleWriter.setUint(0); // reserved
      midiPreambleWriter.setUint(0); // reserved
      midiPreambleWriter.setUint(0); // reserved
    });

    const midiPreamble = midiPreambleWriter.close();
    const midiPreambleOffset = songOffset + songLength;
    const midiPreambleLength = midiPreamble.byteLength;
    const midiMeta = midiMetaWriter.close();
    const midiMetaOffset = midiPreambleOffset + midiPreambleLength;
    const midiMetaLength = midiMeta.byteLength;
    const midiData = midiDataWriter.close();
    const midiDataOffset = midiMetaOffset + midiMetaLength;
    const midiDataLength = midiData.byteLength;

    const writer = new Writer();

    // OFFSETS
    const offsetsWriter = new Writer();
    offsetsWriter.setUint(SONG_HEADER_LENGTH); // self offset
    offsetsWriter.setUint(metaOffset); // meta offset
    offsetsWriter.setUint(songOffset); // song offset
    offsetsWriter.setUint(midiDataOffset); // midi data offset
    offsetsWriter.setUint(midiMetaOffset); // midi meta offset
    offsetsWriter.setUint(midiPreambleOffset); // midi preamble offset
    offsetsWriter.setUint(0); // auto pilot offset
    offsetsWriter.setUint(0); // reserved
    offsetsWriter.setUint(0); // reserved
    offsetsWriter.setUint(0); // reserved
    offsetsWriter.setUint(SONG_OFFSETS_LENGTH); // self length
    offsetsWriter.setUint(metaLength); // meta length
    offsetsWriter.setUint(songLength); // song length
    offsetsWriter.setUint(midiPreambleLength); // midi preamble length
    offsetsWriter.setUint(midiMetaLength); // midi meta length
    offsetsWriter.setUint(midiDataLength); // midi data length
    offsetsWriter.setUint(0); // auto pilot length
    offsetsWriter.setUint(0); // reserved
    offsetsWriter.setUint(0); // reserved
    offsetsWriter.setUint(0); // reserved

    const crc32 = new Crc32();
    crc32.update(offsetsWriter.buffer, offsetsWriter.offset);
    crc32.update(metaWriter.buffer, metaWriter.offset);
    crc32.update(songWriter.buffer, songWriter.offset);
    crc32.update(midiPreambleWriter.buffer, midiPreambleWriter.offset);
    crc32.update(midiMetaWriter.buffer, midiMetaWriter.offset);
    crc32.update(midiDataWriter.buffer, midiDataWriter.offset);
    const crc = crc32.getCRC(true);
    this.header.crc = crc;

    writeSongHeader(writer, crc);
    writer.append(offsetsWriter.close());
    writer.append(meta);
    writer.append(song);
    writer.append(midiPreamble);
    writer.append(midiMeta);
    writer.append(midiData);

    return writer.close();
  }

  copy() {
    const data = this.save();
    const song = Song.parse(data, `${this.name} Copy`);
    song.meta.uuid = crypto.randomUUID();
    return song;
  }

  static parseHeader(buffer: ArrayBuffer) {
    const reader = new Reader(new DataView(buffer), 0);
    return parseSongHeader(reader);
  }

  static parse(buffer: ArrayBuffer, name: string) {
    const reader = new Reader(new DataView(buffer), 0);
    const song = new Song(name);
    song.header = parseSongHeader(reader);
    song.offsets = parseSongOffsets(reader);
    song.meta = parseSongMeta(reader.seek(song.offsets.metaOffset));
    song.tracks = parseSongMidi(reader, song.offsets);
    song.info = parseSongInfo(reader.seek(song.offsets.songOffset));
    return song;
  }
}

function parseSongHeader(reader: Reader) {
  const header = new SongHeader();
  header.type = reader.getString(4);
  header.backwardsCompatibleVersion = reader.getByte();
  header.backwardsCompatibleRevision = reader.getByte();
  header.backwardsCompatibleBuild = reader.getUShort();
  header.crc = reader.getUint();
  header.flags = reader.getByte();
  reader.getByte(); //reserved
  reader.getByte(); //reserved
  reader.getByte(); //reserved
  header.version = reader.getByte();
  header.revision = reader.getByte();
  header.build = reader.getUShort();
  // reserved 20-32
  reader.getUint();
  reader.getUint();
  reader.getUint();
  return header;
}

function parseSongOffsets(reader: Reader) {
  const offsets = new SongOffsets();
  offsets.selfOffset = reader.getUint();
  offsets.metaOffset = reader.getUint();
  offsets.songOffset = reader.getUint();
  offsets.midiDataOffset = reader.getUint();
  offsets.midiMetaOffset = reader.getUint();
  offsets.midiPreambleOffset = reader.getUint();
  offsets.autoPilotOffset = reader.getUint();
  reader.getUint(); // reserved
  reader.getUint(); // reserved
  reader.getUint(); // reserved
  offsets.selfLength = reader.getUint();
  offsets.metaLength = reader.getUint();
  offsets.songLength = reader.getUint();
  offsets.midiPreambleLength = reader.getUint();
  offsets.midiMetaLength = reader.getUint();
  offsets.midiDataLength = reader.getUint();
  offsets.autoPilotLength = reader.getUint();
  reader.getUint(); // reserved
  reader.getUint(); // reserved
  reader.getUint(); // reserved
  return offsets;
}

function parseSongMeta(reader: Reader) {
  const meta = new SongMeta();
  meta.uuid = reader
    .getString(40, true)
    .replace(/\{(.+)\}.*/, '$1')
    .trim();
  meta.drumsetName = reader.getString(127, true).trim();
  meta.defaultDrumsetName = meta.drumsetName;
  // The full available drumset name is padded with \0 to 127 bytes
  // The rest of the meta data is padded with Z's
  return meta;
}

function parseSongInfo(reader: Reader) {
  const info = new SongInfo();
  info.loop = reader.getUint();
  info.bpm = reader.getUint();
  const partCount = reader.getUint();
  reader.getUint(); // reserved
  info.drumset = reader.getString(16, true);
  info.intro = parsePart(reader);
  info.outro = parsePart(reader);
  info.parts = [];
  for (let i = 0; i < partCount; i++) {
    info.parts.push(parsePart(reader));
  }
  return info;
}

function parsePart(reader: Reader) {
  const part = new SongPartInfo();
  part._fillCount = reader.getUint();
  part.bpmDelta = reader.getUint();
  part.repeat = reader.getUShort() == 1;
  part.random = reader.getUShort() == 1;
  part.timeSignatureNumerator = reader.getUint();
  part.timeSignatureDenominator = reader.getUint();
  part.ticksPerBar = reader.getUint();
  part.mainLoopFileIndex = reader.getInt();
  part.transitionFileIndex = reader.getInt();
  part.fillFileIndexes = [
    reader.getInt(),
    reader.getInt(),
    reader.getInt(),
    reader.getInt(),
    reader.getInt(),
    reader.getInt(),
    reader.getInt(),
    reader.getInt(),
  ];
  part.accentFileName = reader.getString(16, true);
  part.effectVolume = reader.getUint();
  part.loopCount = reader.getUint();
  reader.getUint(); // reserved
  reader.getUint(); // reserved
  return part;
}

function parseSongMidi(
  reader: Reader,
  {
    midiPreambleOffset,
    midiPreambleLength,
    midiMetaOffset,
    midiMetaLength,
    midiDataOffset,
  }: SongOffsets,
) {
  const midiParts = midiPreambleLength / 32;
  const midi = [];
  for (let i = 0; i < midiParts; i++) {
    const item = parseMidiMeta(reader.seek(midiPreambleOffset + i * 32));
    reader.seek(midiMetaOffset + item.metaOffset);
    item.fileName = reader.getString(midiMetaLength - item.metaOffset, true);
    item.info = parseMidiTrack(
      reader.seek(midiDataOffset + item.dataOffset),
      item.fileName,
    );
    midi.push(item);
  }
  return midi;
}

function sortMidiEvents(a: MidiEvent, b: MidiEvent) {
  if (a.tick === b.tick) {
    if (a.type === b.type) {
      return a.note - b.note;
    }
    // Types are reversed so that note off events are first for the same tick
    return b.type - a.type;
  }
  return a.tick - b.tick;
}

function parseMidiTrack(reader: Reader, name: string) {
  const track = new SongTrackInfo();
  track.format = reader.getUint();
  track.track = reader.getUint();
  track.tickCount = reader.getUint();
  track.pickupNotesLength = reader.getUint();
  track.additionalNotesLength = reader.getUint();
  track.timeSignatureNumerator = reader.getByte();
  track.timeSignatureDenominator = reader.getByte();
  track.n32ndNotesPerMIDIQuarterNote = reader.getByte();
  track.midiClocksPerMetronomeClick = reader.getByte();
  track.tickToQuarter = reader.getUint();
  track.barLength = reader.getUint();
  track.trigPos = reader.getUint();
  track.bpm = reader.getUint();
  track.index = reader.getUint();
  reader.getUint(); // reserved
  reader.getUint(); // reserved
  const eventCount = reader.getUint();
  let events: MidiEvent[] = [];
  let badEvent = false;
  for (let i = 0; i < eventCount; i++) {
    const tick = reader.getUint();
    const type = reader.getByte();
    const note = reader.getByte();
    const velocity = reader.getByte();
    const event = new MidiEvent(tick, type, note, velocity);
    reader.getByte(); // reserved
    events.push(event);

    if (tick > track.tickCount + track.tickToQuarter * 2) {
      // track.tickToQuarter * 2 is to account for extra ticks
      // this property doesn't exist in song track info, and additionalNotesLength (which may be the same thing? or maybe not) is huge
      badEvent = true;
    }
  }

  events = normalizeMidiEvents(events, track);

  track.events = events;

  if (track.tickToQuarter === 0) {
    const factor =
      track.timeSignatureNumerator / (track.timeSignatureDenominator / 4);
    track.tickToQuarter = track.barLength / factor;
  }

  if (badEvent) {
    logMessageToLocalStorage('Bad event in track ' + name);
    console.warn('Bad event in track', name);
  }

  return track;
}

export function normalizeMidiEvents(events: MidiEvent[], track: SongTrackInfo) {
  events = events.toSorted(sortMidiEvents);

  // Working backwards, find each note-on event,
  //  then search forwards to the next note-off event (or note-on with 0 velocity)
  //  and set the length to the difference
  for (let i = events.length - 1; i >= 0; i--) {
    const event = events[i]!;
    if (event.type === 0 && event.velocity > 0) {
      for (let j = i + 1; j < events.length; j++) {
        const nextEvent = events[j]!;
        if (
          nextEvent.note === event.note &&
          (nextEvent.type === 1 || nextEvent.velocity === 0)
        ) {
          event.length = nextEvent.tick - event.tick;
          break;
        }
      }
    }
  }

  // Remove any note on events with a velocity of 0
  events = events.filter(
    (event) => !(event.type === 0 && event.velocity === 0),
  );

  // Remove all note off events
  events = events.filter((event) => event.type === 0);

  // For each event, search backwards and set the length to the difference between
  //   the current event and the next event if it is shorter than the existing length
  events.forEach((event, i) => {
    for (let j = i + 1; j < events.length; j++) {
      const nextEvent = events[j]!;
      if (nextEvent.type === 0 && nextEvent.note === event.note) {
        event.length = Math.min(event.length, nextEvent.tick - event.tick);
        break;
      }
    }
  });

  // Remove any note with a zero length
  events = events.filter((event) => event.length > 0);

  // For each event, ensure that the tick + length is not longer than tickCount
  events.forEach((event) => {
    if (event.tick + event.length > track.tickCount) {
      event.length = track.tickCount - event.tick;
    }
    // Events after the end of the track are set to the length of a quarter note
    if (event.tick >= track.tickCount) {
      event.length =
        (track.tickToQuarter * (track.timeSignatureDenominator / 4)) /
        track.timeSignatureNumerator;
    }
  });

  return events;
}

function parseMidiMeta(reader: Reader) {
  const meta = new SongTrack();
  meta.dataOffset = reader.getUint();
  meta.metaOffset = reader.getUint();
  meta.crc = reader.getUint();
  // reserved 12 - 28
  return meta;
}

function writeSongHeader(writer: Writer, crc: number) {
  writer.setString('BBSF', false);
  writer.setByte(2); // backwards compatibility version
  writer.setByte(0); // backwards compatibility revision
  writer.setUShort(5712); // backwards compatibility build
  writer.setUint(crc);
  writer.setByte(0); // flags
  writer.setByte(0); // reserved
  writer.setByte(0); // reserved
  writer.setByte(0); // reserved
  writer.setByte(0); // version
  writer.setByte(0); // revision
  writer.setUShort(0); // build
  writer.setUint(0); // reserved
  writer.setUint(0); // reserved
  writer.setUint(0); // reserved
}

function writeSongPart(
  writer: Writer,
  part: SongPartInfo,
  trackMap: Map<number, number>,
) {
  writer.setUint(part.fillCount ?? 0);
  writer.setUint(part.bpmDelta ?? 0);
  writer.setUShort(part.repeat ?? true ? 1 : 0);
  writer.setUShort(part.random ? 1 : 0);
  writer.setUint(part.timeSignatureNumerator ?? 4);
  writer.setUint(part.timeSignatureDenominator ?? 4);
  writer.setUint(part.ticksPerBar ?? 1920);
  writer.setInt(trackMap.get(part.mainLoopFileIndex) ?? -1);
  writer.setInt(trackMap.get(part.transitionFileIndex) ?? -1);
  (part.fillFileIndexes ?? new Array(8).fill(-1)).forEach((index) => {
    writer.setInt(trackMap.get(index) ?? -1);
  });
  writer.setString(part.accentFileName ?? '', false, 16);
  writer.setUint(part.effectVolume ?? 100);
  writer.setUint(part.loopCount ?? 0);
  writer.setUint(0); // reserved
  writer.setUint(0); // reserved
}

function writeMidiData(writer: Writer, item: SongTrack) {
  writer.setUint(item.info.format ?? 0);
  writer.setUint(item.info.track ?? 0);
  writer.setUint(item.info.tickCount ?? 0);
  writer.setUint(item.info.pickupNotesLength ?? 0);
  writer.setUint(item.info.additionalNotesLength ?? 0);
  writer.setByte(item.info.timeSignatureNumerator ?? 0);
  writer.setByte(item.info.timeSignatureDenominator ?? 0);
  writer.setByte(item.info.n32ndNotesPerMIDIQuarterNote ?? 0);
  writer.setByte(item.info.midiClocksPerMetronomeClick ?? 0);
  writer.setUint(item.info.tickToQuarter ?? 0);
  writer.setUint(item.info.barLength ?? 0);
  writer.setUint(item.info.trigPos ?? 0);
  writer.setUint(item.info.bpm ?? 0);
  writer.setUint(item.info.index ?? 0);
  writer.setUint(0); // reserved
  writer.setUint(0); // reserved
  // x 2 because we’re going to write a note off for every note on
  writer.setUint(item.info.events.length * 2);
  item.info.events
    .flatMap((event) => [
      event,
      // Write a note off event for each note on event
      new MidiEvent(event.tick + event.length, 1, event.note, 0, 0),
    ])
    .sort(sortMidiEvents)
    .forEach((event) => {
      writer.setUint(event.tick);
      writer.setByte(event.type);
      writer.setByte(event.note);
      writer.setByte(event.velocity);
      writer.setByte(0); // reserved
    });
}
