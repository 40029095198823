import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import {
  CreateDrumsetCommand,
  DeleteDrumsetCommand,
  DuplicateDrumsetCommand,
  RenameDrumsetCommand,
  SortDrumsetsAlphabeticallyCommand,
  SortDrumsetsCommand,
} from 'editor/models/drumset-commands';
import type ProjectManagerService from 'editor/services/project-manager';
import type { DrumsetFile } from 'editor/services/project-manager';
import type UndoManagerService from 'editor/services/undo-manager';
import nameFilter from 'editor/utils/name-filter';
import wait from 'editor/utils/wait';

export default class RestrictedDrumsetsController extends Controller {
  @service declare router: RouterService;
  @service declare projectManager: ProjectManagerService;
  @service declare undoManager: UndoManagerService;

  nameFilter = nameFilter;
  @tracked menuDrumset?: DrumsetFile;
  @tracked ignoreNextClickOutside: boolean = false;

  get drumsets() {
    return this.projectManager.drumsets;
  }

  get selectedDrumset() {
    return this.projectManager.currentDrumset;
  }

  get currentProject() {
    return this.projectManager.currentProject!;
  }

  @action
  showMenuFor(drumset: DrumsetFile | undefined, ev?: MouseEvent) {
    if (!drumset && this.ignoreNextClickOutside) {
      this.ignoreNextClickOutside = false;
      return;
    }
    if (this.menuDrumset != drumset) {
      ev?.preventDefault();
      ev?.stopPropagation();
      if (this.menuDrumset && drumset) {
        this.ignoreNextClickOutside = true;
      }
      this.menuDrumset = drumset;
    }
    if (!drumset) {
      this.menuDrumset = undefined;
      return;
    }
  }

  @action
  shouldShowMenuFor(drumset: DrumsetFile) {
    return this.menuDrumset === drumset;
  }

  @action
  sortDrumsets(oldIndex: number, newIndex: number) {
    this.undoManager.executeCommand(
      new SortDrumsetsCommand(this.projectManager, oldIndex, newIndex),
    );
  }

  @action
  renameDrumset(drumset: DrumsetFile, name: string) {
    if (name !== drumset.name) {
      this.undoManager.executeCommand(new RenameDrumsetCommand(drumset, name));
    }
  }

  @action
  selectDrumset(drumset: DrumsetFile) {
    this.router.transitionTo('restricted.drumsets.drumset', drumset.path);
  }

  @action
  addDrumset() {
    const command = new CreateDrumsetCommand(this.projectManager);
    const previousDrumset = this.projectManager.currentDrumset;
    command.on('execute', (drumset) =>
      this.router.transitionTo('restricted.drumsets.drumset', drumset.path),
    );
    command.on('reverse', () => {
      this.projectManager.currentDrumset = previousDrumset;
      this.router.transitionTo('restricted.drumsets.index');
    });
    this.undoManager.executeCommand(command);
  }

  @action
  sortDrumsetsAlphabetically() {
    this.undoManager.executeCommand(
      new SortDrumsetsAlphabeticallyCommand(this.projectManager),
    );
  }

  @action
  deleteDrumset(drumset: DrumsetFile) {
    const command = new DeleteDrumsetCommand(this.projectManager, drumset);
    command.on('execute', () => {
      this.router.transitionTo('restricted.drumsets.index');
    });
    command.on('reverse', (drumset) => {
      this.router.transitionTo('restricted.drumsets.drumset', drumset.path);
    });
    this.undoManager.executeCommand(command);
  }

  @action
  async duplicateDrumset(drumset: DrumsetFile, ev?: MouseEvent) {
    ev?.stopPropagation();
    await wait(async () => {
      const command = new DuplicateDrumsetCommand(this.projectManager, drumset);
      command.on('execute', (drumset) => {
        this.router.transitionTo('restricted.drumsets.drumset', drumset.path);
      });
      command.on('reverse', (drumset) => {
        this.router.transitionTo('restricted.drumsets.drumset', drumset.path);
      });
      try {
        await this.undoManager.executeCommand(command);
      } catch (err: any) {
        alert(`Error duplicating drumset`);
        throw err;
      }
    });
  }

  @action
  exportDrumset(drumset: DrumsetFile) {
    this.projectManager.exportDrumset(drumset);
  }
}
