import { template } from "@ember/template-compiler";
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import Button from './button';
interface HelpPopupSignature {
    Element: HTMLFormElement;
    Args: {
        onClose: () => void;
    };
    Blocks: {
    };
}
export default class HelpPopupComponent extends Component<HelpPopupSignature> {
    @tracked
    isSending = false;
    @tracked
    isSent = false;
    @tracked
    error: string | null = null;
    constructor(owner1: unknown, args1: HelpPopupSignature['Args']){
        super(owner1, args1);
    }
    startSending() {
        this.isSending = true;
        this.isSent = false;
        this.error = null;
    }
    sendingComplete() {
        this.isSending = false;
        this.isSent = true;
        this.error = null;
    }
    errorSending(error1: string) {
        this.isSending = false;
        this.isSent = false;
        this.error = error1;
    }
    @action
    sendHelpRequest(ev1: SubmitEvent) {
        ev1.preventDefault();
        const form1 = <HTMLFormElement>ev1.target;
        // this.sendingComplete();
        const subject1 = 'BBMO Support';
        let body1 = form1['message'].value.trim();
        const logs1 = localStorage.getItem('logs') || '';
        body1 = body1 + '\n\n' + logs1;
        const encodedSubject1 = encodeURIComponent(subject1);
        const encodedBody1 = encodeURIComponent(body1);
        const constructedUrl1 = `mailto:support@singularsound.com?subject=${encodedSubject1}&body=${encodedBody1}`;
        window.open(constructedUrl1, '_blank').focus();
    }
    @action
    downloadLogs() {
        const logs1 = localStorage.getItem('logs') || '';
        const blob1 = new Blob([
            logs1
        ], {
            type: 'text/plain'
        });
        const link1 = document.createElement('a');
        link1.href = URL.createObjectURL(blob1);
        link1.download = 'BBMO.log';
        link1.click();
    }
    static{
        template(`
    <form class="flex flex-col gap-2" {{on "submit" this.sendHelpRequest}}>
      <h1 class="text-xl font-bold">How can we help?</h1>
      <p>Please let us know if you have a question, a suggestion, or need help with
        something. Download the log if reporting a bug and send it to
        support@singularsound.com.</p>
      <p>We’ll email you a reply (usually within 2-3 days).</p>

      {{#if this.error}}
        <p class="text-red-600">{{this.error}}</p>
      {{/if}}
      <textarea
        name="message"
        class="w-full h-32 p-2 border rounded"
        aria-label="Message"
      ></textarea>
      <Button
        @icon="paper-plane"
        type="submit"
        class="text-white bg-green-600 hover:bg-green-700 p-2"
      >Send</Button>
      <Button
        @icon="download"
        class="text-white bg-green-600 hover:bg-green-700 p-2"
        {{on "click" this.downloadLogs}}
      >Download Log</Button>
    </form>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        HelpPopup: typeof HelpPopupComponent;
    }
}
